var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-container"},[_c('div',{staticClass:"company-container common-box"},[_c('Header'),_c('div',{staticClass:"company-center"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"company-video"},[_c('video',{ref:"video",staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/video/60s.mp4"),"controls":"","muted":"","autoplay":"","preload":"metadata","webkit-playsinline":"true","playsinline":"true","x-webkit-airplay":"allow","x5-video-player-type":"h5","x5-video-player-fullscreen":"false","x5-video-orientation":"landscape"},domProps:{"muted":true}},[_vm._v(" 抱歉，您的浏览器不支持内嵌视频! ")])]),_vm._m(2),_c('div',{staticClass:"company-images"},[_c('div',[_c('el-image',{attrs:{"src":require('@/assets/images/company1.png'),"preview-src-list":[
              require('@/assets/images/company1.png'),
              require('@/assets/images/company2.png'),
              require('@/assets/images/company3.png'),
              require('@/assets/images/company4.png'),
            ]}})],1),_c('div',[_c('el-image',{attrs:{"src":require('@/assets/images/company2.png'),"preview-src-list":[
              require('@/assets/images/company1.png'),
              require('@/assets/images/company2.png'),
              require('@/assets/images/company3.png'),
              require('@/assets/images/company4.png'),
            ]}})],1)]),_vm._m(3),_c('div',{staticClass:"company-images"},[_c('div',[_c('el-image',{attrs:{"src":require('@/assets/images/company3.png'),"preview-src-list":[
              require('@/assets/images/company1.png'),
              require('@/assets/images/company2.png'),
              require('@/assets/images/company3.png'),
              require('@/assets/images/company4.png'),
            ]}})],1),_c('div',[_c('el-image',{attrs:{"src":require('@/assets/images/company4.png'),"preview-src-list":[
              require('@/assets/images/company1.png'),
              require('@/assets/images/company2.png'),
              require('@/assets/images/company3.png'),
              require('@/assets/images/company4.png'),
            ]}})],1)]),_c('div',{staticClass:"company-content"},[_vm._v("As a Science-Based Dietary Nutrition company, our values are Quality, Safety, Responsibility.")]),_vm._m(4)]),_c('BestSellers')],1),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-page-title detail-page-title-pc"},[_c('div',{staticClass:"detail-page-title-left"},[_c('div',[_vm._v("Science-Based Dietary Nutrition")]),_c('div',{staticClass:"detail-page-subtitle"},[_c('div',[_vm._v("Founded in 2014, Mission Hill Foods Group focuses ")]),_c('div',[_vm._v("on dietary supplement manufacturing and wholesale.")])])]),_c('div',{staticClass:"detail-page-title-right"},[_c('img',{attrs:{"src":require("@/assets/images/company-right1.png"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/images/company-right2.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-page-title detail-page-title-m"},[_c('div',{staticClass:"detail-page-title-left"},[_c('div',[_vm._v("Science-Based Dietary Nutrition")]),_c('div',{staticClass:"detail-page-title-right"},[_c('img',{attrs:{"src":require("@/assets/images/company-right1.png"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/images/company-right2.png"),"alt":""}})]),_c('div',{staticClass:"detail-page-subtitle"},[_c('div',[_vm._v("Founded in 2014, Mission Hill Foods Group focuses ")]),_c('div',[_vm._v("on dietary supplement manufacturing and wholesale.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company-content"},[_c('div',[_vm._v("Our company is headquartered in California, USA, with offices also located in British Columbia, Canada, and New York, USA.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company-content"},[_c('div',[_vm._v("By 2023, we achieved an annual sales volume of 12 million bottles across 15 different categories of dietary products, serving clients and consumers in North America, Japan, China, and Southeast Asia markets.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company-based"},[_c('div',{staticClass:"detail-page-title",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"detail-page-title-left"},[_c('div',{staticStyle:{"color":"#fff","font-weight":"500"}},[_vm._v("Mission Hill Foods")]),_c('div',{staticStyle:{"color":"#fff","font-weight":"500","margin-top":"-12px"}},[_vm._v("Science-Based Dietary Nutrition")])]),_c('div',{staticClass:"detail-page-title-right"},[_c('img',{attrs:{"src":require("@/assets/images/icon-white1.png"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/images/icon-white2.png"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/images/icon-white3.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }